import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import banc from "../../public/banc_logo.jpg";
import Content_Left from "components/Content_Left";
import bank_photo from "../../public//bank.jpg";
import leftarrow from "../../public/icons/Long_arrow.png";
import Carousel from "react-multi-carousel";
import creativeLab from "../../public/creativelablogo.png";
import arduino from "../../public/creativeLabArduino.jpg";
import "react-multi-carousel/lib/styles.css";
import Content_Right from "components/Content_Right";
import creativeLabHeadquarters from "../../public/creativeLabHeadquarters.jpg";
import iot from "../../public/iot.png";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Professionalexperience = () => {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <div className="CarouselButtonContainer" onClick={() => previous()}>
          <span>Previous</span>
          <img className="CarouselButton" src={leftarrow} />
        </div>
        <div className="CarouselButtonContainer" onClick={() => next()}>
          <span>Next</span>
          <img className="CarouselButton Right" src={leftarrow} />
        </div>
      </div>
    );
  };
  return (
    <div className="OtherPagesContainer ">
      <Carousel
        additionalTransfrom={0}
        centerMode={false}
        draggable
        focusOnSelect={false}
        containerClass="CarouselClass"
        itemClass=""
        infinite={true}
        responsive={responsive}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside={true}
      >
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="CreativeLabBackground">
            <div className={"pagetTitle"}>
              extracurricular<br></br>activities
            </div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Left ClassName={"creativelab"} logo={creativeLab}>
            <div className="OtherPagesContainerInnerText">
              <h1>
                {" "}
                Managerial position in the team Manager department at the
                Creative Lab ISI
              </h1>
              <h2>(October 1 2021-July 31 2022)</h2>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp; Mission: Administration human
                resources, coordinating logistics, managing projects, events,
                and information sessions, and supervising participants.
              </p>
              <p>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;About Creative Lab: Creative Lab is a
                hierarchical club with a Team Leader at the top. Just below the
                Team Manager, the head of the Team Manager department, followed
                by the head of the sponsoring team, which is primarily
                responsible for providing resources for the club. There is also
                the Media Team manager who oversees advertising, design, and
                communications. Finally, there is the Event Team. Each of these
                four teams is composed of four bureaucratic members. All four
                teams collaborate to coordinate and manage four main
                departments: the Development Department, IoT Department,
                Problem-Solving Department, and, last but not least, the Design
                Department.
              </p>
            </div>
            <div className="OtherPagesContainerInnerText">
              <p>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp; Milestones: Since its creation,
                Creative Lab has organized numerous events. One of the events I
                contributed to organizing was "Creative Learning," a 48-hour
                data science event consisting of consecutive workshops and
                conferences held at the Marina Palace Hammamet hotel on February
                6th and 7th, 2021. The event featured three distinguished
                speakers: Mohamed Aziz Najjar, who covered topics such as data
                history, data science, data projects, the impact of information,
                data economic models, and the process of managing data. Mourfik
                Ayadi discussed "the artificial intelligence in fintech," while
                Taghouti Ghofrane focused on "natural language processing."
              </p>
            </div>
          </Content_Left>

          <Content_Right ClassName={"iot"} logo={iot} sourceImage={arduino}>
            <div className="OtherPagesContainerInnerText">
              <h1>My contributions. </h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
              <p>
                In addition to my role in organizing the event, I also served as
                the tutor for the Arduino All-in-One workshop within the IoT
                department. During the session, I aimed to cover all essential
                aspects of IoT in a fast-paced session. This included a
                comparison of various electronic boards and microcontroller
                architectures, with a specific focus on different open-source
                Arduino boards. I also explored the use of multiple sensors,
                such as the ultrasonic distance sensor. Despite encountering
                some minor issues with equipment functionality, we achieved a
                90% success rate in our manipulations using Arduino IDE. While
                I'm not particularly passionate about circuit boards, my
                involvement in the department stemmed from a genuine curiosity
                about the broader IoT domain. The workshop proved to be an
                enjoyable experience overall.{" "}
              </p>{" "}
            </div>
          </Content_Right>
          <Content_Left
            ClassName={"iot bank"}
            logo={creativeLabHeadquarters}
            bancSource={bank_photo}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>What did I gain from being part of the club?</h1>{" "}
              <p>
                In addition to getting to know cheerful and highly talented
                individuals from various parts of the Higher Institute of
                Computer Science and gaining insight into the future of computer
                engineering and the Internet of Things, I also immersed myself
                in various design tools like Adobe Photoshop, Adobe Premiere,
                and Adobe Illustrator. The advice from older students helped me
                a lot, particularly in technical areas like cloud and app
                development. Furthermore, I acquired skills in organizing my
                tasks, managing resources, and leading a team. This experience
                taught me the importance of balancing creativity with adhering
                to timelines.
              </p>{" "}
            </div>
            <div className="OtherPagesContainerInnerText"></div>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.





            </span>
          </div>
          <div className="footerBackgroundCreativeLab">
            <Footer />
          </div>
        </div>
        <div className="OtherPagesContainer ">
      <div
        className="OtherPagesContainerInnerText sideBar"
        style={{
          position: "absolute",
          zIndex: "99",
          bottom: "0",
          left: "10px",
          width: "30vh",
        }}
      >
        <p> </p>
      </div>
      <div className="securinets">
      <div className={"pagetTitle"}>
              extracurricular<br></br>activities
            </div>      </div>
      <div className="ImageSpan">
        <span style={{ width: "33vh" }}></span>
        <div className="ImageSpanSpans">
          <span>
            <number className="number">01</number>&nbsp;&nbsp;&nbsp;&nbsp;hire
            me{" "}
          </span>
        </div>
      </div>
      <div className="GoalsContainer">
    <div style={{width:"25%"}}></div>
    <div style={{width:"75%",height:"auto"}}>
    <div className="OtherPagesContainerInnerText">
<h1>I was a member of both Securinets and Freeways ISI.</h1>
</div>
    <div className="OtherPagesContainerInnerTextGoals">
    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberTitle">&nbsp;&nbsp;&nbsp;&nbsp;</number> <h1>To be honest, I gained a fair amount of knowledge from those clubs, particularly in open-source operating systems, especially those used in pentesting like Kali Linux and Black Arch. I also delved into cyber security attacks and various techniques, as well as the open-source ideology, and more. However, I eventually decided to step away from both clubs for a few reasons. One of them being that I wanted to channel all of my focus and energy into Creative Lab, rather than just skimming the surface in each club.</h1></div>


</div>


</div>
   </div>
   
      <Content_Left ClassName={"bank"} bancSource={bank_photo}>
     <></>
   
      </Content_Left>

      <div style={{ width: "100%", padding: "20px", background: "#000" }}>
        <span className="expression">
          <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
          It's the journey that counts, not the destination.




</span>
      </div>
      <div className="securinetsfoter">
        <Footer />
      </div>
    </div>
    
      </Carousel>{" "}
    </div>
  );
};
export default Professionalexperience;

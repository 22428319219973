import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import ResponsiveCertfication from "../../public/Responsive.png";
import Content_Left from "components/Content_Left";
import bank_photo from "../../public//bank.jpg";
import leftarrow from "../../public/icons/Long_arrow.png";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import Content_Right from "components/Content_Right";
import bank from "../../public/banc_logo.jpg"
import freeCodecamp from "../../public/freeCodecamp.svg";
import Javascript from "../../public/Javascripts.png";
import certificationstage from "../../public/certificationBt.jpg";
import certificationParttime from "../../public/certificationbarista.jpg";
import certificationmyschool from "../../public/certification_myschool.jpg";
import certificationfrancais from "../../public/certificationfrancais.jpg";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Professionalexperience = () => {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <div className="CarouselButtonContainer" onClick={() => previous()}>
          <span>Previous</span>
          <img className="CarouselButton" src={leftarrow} />
        </div>
        <div className="CarouselButtonContainer" onClick={() => next()}>
          <span>Next</span>
          <img className="CarouselButton Right" src={leftarrow} />
        </div>
      </div>
    );
  };
  return (
    <div className="OtherPagesContainer ">
      <Carousel
        additionalTransfrom={0}
        centerMode={false}
        draggable
        focusOnSelect={false}
        containerClass="CarouselClass"
        itemClass=""
        infinite={true}
        responsive={responsive}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside={true}
      >
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="certifications">
            <div className={"pagetTitle"}>Certifications</div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Right
            ClassName={"freeCodecamp"}
            logo={freeCodecamp}
            sourceImage={ResponsiveCertfication}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>Responsive Web Design </h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
              <h2>300 Hours of Immersive Learning</h2>
              <p>
                {" "}
                FreeCodeCamp's Responsive Web Design Certificate represents a
                dedication of 300 hours to immersive learning. This extensive
                program covers HTML, CSS, and responsive design principles,
                ensuring you possess the expertise to create websites that adapt
                flawlessly to any device or screen size.
              </p>
              <p> Click me or click on the certificate to see more!</p>
            </div>
          </Content_Right>

          <Content_Left bancSource={bank_photo}>
            <></>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.
            </span>
          </div>
          <div className="certificationsfooter">
            <Footer />
          </div>
        </div>
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="certifications">
            <div className={"pagetTitle"}>Certifications</div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Right
            ClassName={"freeCodecamp"}
            logo={freeCodecamp}
            sourceImage={Javascript}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>JavaScript Algorithms and Data Structures </h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
              <h2>Topics:</h2>
              <p>
                {" "}
                fundamentals of JavaScript(OOP and FP) including variables,
                objects, loops, and functions
              </p>
              <p> Click me or click on the certificate to see more!</p>
            </div>
          </Content_Right>

          <Content_Left bancSource={bank_photo}>
            <></>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.
            </span>
          </div>
          <div className="certificationsfooter">
            <Footer />
          </div>
        </div>
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="certifications">
            <div className={"pagetTitle"}>Certifications</div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Right
            ClassName={"freeCodecamp"}
            sourceImage={certificationstage}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>One-month internship at the Bank of Tunisia certificate</h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
            <p> Click me or click on the certificate to see more!</p>
          
            </div>
          </Content_Right>

          <Content_Left bancSource={bank_photo}>
            <></>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.
            </span>
          </div>
          <div className="certificationsfooter">
            <Footer />
          </div>
        </div>
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="certifications">
            <div className={"pagetTitle"}>Certifications</div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Right
            ClassName={"freeCodecamp"}
            sourceImage={certificationmyschool}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>One Month Of Accelerated courses in Algorithmics</h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
            <p> Click me or click on the certificate to see more!</p>
          
            </div>
          </Content_Right>

          <Content_Left bancSource={bank_photo}>
            <></>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.
            </span>
          </div>
          <div className="certificationsfooter">
            <Footer />
          </div>
        </div>
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="certifications">
            <div className={"pagetTitle"}>Certifications</div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Right
            ClassName={"freeCodecamp"}
            sourceImage={certificationfrancais}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>One Month Of Accelerated courses in French</h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
            <p> Click me or click on the certificate to see more!</p>
          
            </div>
          </Content_Right>

          <Content_Left bancSource={bank_photo}>
            <></>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.
            </span>
          </div>
          <div className="certificationsfooter">
            <Footer />
          </div>
        </div>
        <div className="OtherPagesContainer ">
          <div
            className="OtherPagesContainerInnerText sideBar"
            style={{
              position: "absolute",
              zIndex: "99",
              bottom: "0",
              left: "10px",
              width: "30vh",
            }}
          >
            <p> </p>
          </div>
          <div className="certifications">
            <div className={"pagetTitle"}>Certifications</div>
          </div>
          <div className="ImageSpan">
            <span style={{ width: "33vh" }}></span>
            <div className="ImageSpanSpans">
              <span>
                <number className="number">01</number>
                &nbsp;&nbsp;&nbsp;&nbsp;hire me{" "}
              </span>
            </div>
          </div>

          <Content_Right
            ClassName={"freeCodecamp"}
            sourceImage={certificationParttime}
          >
            <div className="OtherPagesContainerInnerText">
              <h1>Part-time Job Certificate</h1>{" "}
            </div>
            <div className="OtherPagesContainerInnerText">
            <p> Click me or click on the certificate to see more!</p>
          
            </div>
          </Content_Right>

          <Content_Left bancSource={bank_photo}>
            <></>
          </Content_Left>

          <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              It's the journey that counts, not the destination.
            </span>
          </div>
          <div className="certificationsfooter">
            <Footer />
          </div>
        </div>
      </Carousel>{" "}
    </div>
  );
};
export default Professionalexperience;

import React, { useState } from "react";
import SideBarContext from "./SideBarContext";

const SideBarContextProvider = ({ children }) => {
    const [toggled, setToggled] = useState(false);

    const toggle = (newToggled) => {
        setToggled(newToggled);
    console.log("toggled",toggled)
    }

    return (
        <SideBarContext.Provider value={{toggled, toggle }}>
            {children}
        </SideBarContext.Provider>
    );
}

export default SideBarContextProvider;
import React from "react";
import "../public/styles.css";
const All_Content_Right = ({Title, children, logo, ClassName, sourceImage }) => {
  return (
    <div ClassName="ContentContainer">

      <div className="ContainerDirectionRightAll">
        <div className="Align"><div style={{ width: "25%" }}></div> <h1 className="OtherPagesContainerInnerTextAll" style={{width:"75%"}}>{Title}</h1></div>
        <div className="ContainerDirectionRight">
        <div style={{ width: "25%" }}></div>
        <div className="ContainerRightDirectionRight">
          {children}
        </div>
        <div className="BottomImageAll">
        <img src={logo} className={ClassName} />

        <img src={sourceImage} />
      </div>
      </div>
 
    </div>
    </div>
  );
};
export default All_Content_Right;

import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import Content_Left from "components/Content_Left";
import bank_photo from "../../public//bank.jpg";

const Parttimejobs = () => {
  return (
    <div className="OtherPagesContainer ">
      <div
        className="OtherPagesContainerInnerText sideBar"
        style={{
          position: "absolute",
          zIndex: "99",
          bottom: "0",
          left: "10px",
          width: "30vh",
        }}
      >
        <p> </p>
      </div>
      <div className="PartTimeJobBackground">
        <div className={"pagetTitle"}>Part-Time Jobs</div>
      </div>
      <div className="ImageSpan">
        <span style={{ width: "33vh" }}></span>
        <div className="ImageSpanSpans">
          <span>
            <number className="number">01</number>&nbsp;&nbsp;&nbsp;&nbsp;hire
            me{" "}
          </span>
        </div>
      </div>

      <Content_Left ClassName={"bank"} bancSource={bank_photo}>
        <div className="OtherPagesContainerInnerText">
          <h1> barista at a local coffee shop(Voltaire).</h1>
          <h2>On and Off since 2019</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; While some may argue that working as a barista may not be the typical job to highlight on a web resume (especially for an information technology student), I strongly disagree: Meeting and serving over 200 strangers on a daily basis, catering to the needs of the coffee shop owner, dealing with unexpected challenges and even working seven days a week for months on end while still pursuing my degree, has taught me the way to deal with unforeseen circumstances.Listening to client complaints and trying to resolve their issues, as well as managing a game night with over 300 attendees alongside just three other colleagues, has left a lasting impact on my personality.

          </p>
       
        </div>
        <div className="OtherPagesContainerInnerText">
       
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;
Before 2019, the year I began this geek, I struggled to engage in a complete and natural conversations with strangers(or even sometimes people I know). I often kept my opinions to myself, and my thoughts rarely reached the outside world. However, these very challenges have, in a peculiar way, transformed me into a remarkable team player. I now willingly offer assistance to my colleagues, even when it hasn't been requested, and I'm unafraid to seek help when I need it. If I make a mistake, I take full responsibility, acknowledging it and endeavoring not to repeat it in the future.
          </p>

          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            Furthermore, the manager, who previously supervised a team of over 400 employees at Sagemcom, approached me with consideration, understanding that I am still completing my degree. In a sense, these past four years have resembled the experience of working in a genuine company.          </p>

  
        </div>
      </Content_Left>

      <div style={{ width: "100%", padding: "20px", background: "#000" }}>
        <span className="expression">
          <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
          Not the most common place to pick up soft skills, but definitely the best place for personality development.        </span>
      </div>
      <div className="footerPartTimeJobBackground">
        <Footer />
      </div>
    </div>
  );
};
export default Parttimejobs;

import React, { useRef, useEffect } from "react";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import styles from "./styles.module.css";
import "./styles.scss";
interface PageProps {
  offset: number;
  gradient: string;
}

const Page = ({ offset, gradient }: PageProps) => (
  <>
    <ParallaxLayer offset={offset} speed={0.2}>
      <div className={`${styles.slopeBegin} ${styles.slopeText}`}>
       {offset===0 && <h1>CREATIVITY ENTHUSIAST</h1>}
       {offset===1 && <h1>CONSTANT LEARNER</h1>}
       {offset===2 && <h1>ADVENTURE SEEKER</h1>}
      </div>
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.6}>
      <div className={`${styles.slopeEnd} ${styles[gradient]}`}>
        <div className={`${styles.slopeBegin1} ${styles.slopeText1}`}>
        {offset===0 && <h1>SKANDER NEFLI</h1>}
       {offset===1 && <h1>SEEKING</h1>}
       {offset===2 && <h1 style={{ fontSize: '3vmin'   }}>INTERNSHIP</h1>}        </div>
        <div className={`${styles.rightRectangle}`}>
          <div className="untitled">
            <div className="untitled__slides">
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                  <span>&nbsp;&nbsp;&nbsp;I</span>
                  <span>AM</span>
             
                </div>
              </div>
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                <span>None</span>
                  <span>Stop</span>
        
                </div>
              </div>
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                <span>Out Of</span>
                  <span>The Norm</span>  
                
                </div>
              </div>
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                  <span>Big</span>
                  <span>Dreamer</span>
          
                </div>
              </div>
            </div>
            <div className="untitled__shutters"></div>
          </div>
        </div>
      </div>
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.3}>
      <div className={`${styles.slopeEnd} ${styles[gradient]}`}>
        <div className={`${styles.bottomRectangle}`}>
          <div className="untitled">
            <div className="untitled__slides">
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                  <span>I</span>
                  <span>AM</span>
            
                </div>
              </div>
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
               <span>None</span>
                  <span>Stop</span>
                
                </div>
              </div>
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                <span>Out Of</span>
                  <span>The Norm</span> 
             
                </div>
              </div>
              <div className="untitled__slide">
                <div className="untitled__slideBg"></div>
                <div className="untitled__slideContent">
                  <span>Big</span>
                  <span>Dreamer</span>
          
                </div>
              </div>
            </div>
            <div className="untitled__shutters"></div>
          </div>{" "}
        </div>
      </div>
    </ParallaxLayer>
  </>
);

const Header = () => {
  const parallax = useRef<IParallax>(null);

  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };

  useEffect(() => {
    let currentPage = 0;

    const interval = setInterval(() => {
      currentPage = (currentPage + 1) % 3;
      scroll(currentPage);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${styles.paralaxContainer}`}>
      <Parallax
        className={styles.container}
        ref={parallax}
        pages={3}
        horizontal
      >
        <Page offset={0} gradient="pink" />
        <Page offset={1} gradient="teal" />
        <Page offset={2} gradient="tomato" />
      </Parallax>
    </div>
  );
};
export default Header;

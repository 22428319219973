import React from "react";
import "../public/styles.css";
const Content_Left=({children, logo,ClassName,sourceImage,bancSource})=>{
return(
<div ClassName="ContentContainer">
<div className="ContainerDirectionLeft">
    <div style={{width:"25%"}}></div>
    <div  className="ContainerLeft">
    {children[0]}
    </div>
    <div className="ContainerRightDirectionLeft">
    {children[1]}
    <img src={logo} className={ClassName}/>
    </div>
</div>
<div className="BottomImage">
<div style={{width:"25%"}}></div>
{sourceImage &&<img src={sourceImage} />}

{bancSource &&<div className="backgroundDiv" style={{ backgroundImage: `url(${bancSource})` }}></div>}

</div>
</div>
)
}
export default Content_Left;
import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import banc from "../../public/banc_logo.jpg";
import Content_Left from "components/Content_Left";
import bank_photo from "../../public//bank.jpg";
const Professionalexperience = () => {
  return (
    <div className="OtherPagesContainer ">
      <div
        className="OtherPagesContainerInnerText sideBar"
        style={{
          position: "absolute",
          zIndex: "99",
          bottom: "0",
          left: "10px",
          width: "30vh",
        }}
      >
        <p> </p>
      </div>
      <div className="WorkPageContainerBackground">
        <div className={"pagetTitle"}>Experience</div>
      </div>
      <div className="ImageSpan">
        <span style={{ width: "33vh" }}></span>
        <div className="ImageSpanSpans">
          <span>
            <number className="number">01</number>&nbsp;&nbsp;&nbsp;&nbsp;hire
            me{" "}
          </span>
        </div>
      </div>

      <Content_Left ClassName={"bank"} logo={banc} bancSource={bank_photo}>
        <div className="OtherPagesContainerInnerText">
          <h1>One-month internship at the Bank of Tunisia.</h1>
          <h2>(August 1 2020-August 30 2020)</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; It felt a bit strange applying the
            theoretical knowledge to a practical use. However, despite it being
            my sole professional internship during my academic years, it has
            proven to be an invaluable experience and a crucial step towards
            entering the professional world. This internship brought to life all
            the abstract information I had learned. Contrary to the common
            belief that work experience differs greatly from academic studies, I
            found that this wasn't the case, especially within the technical
            team of the bank.
          </p>
          <p>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;I wasn't assigned to a specific role as my
            mentor was the director of the IT department. This meant that each
            day I engaged in a variety of tasks, including hardware maintenance,
            network and database administration. Given the critical nature of
            these areas, I was never left unsupervised. I received guidance at
            every step to ensure precision and accuracy.
          </p>
        </div>
        <div className="OtherPagesContainerInnerText">
          <p>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;One of the most remarkable experiences was
            accompanying one of my internship supervisors on a Saturday to
            prepare for the reopening of a branch. This involved a complete
            overhaul of the network infrastructure, including on-premises
            servers, phones, shared equipment, cash counters, and workstations,
            along with dealing with the intricate web of cables.
          </p>

          <p>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;In summary, my internship at the Bank of
            Tunisia was far from the typical 'go fetch coffee' type of
            experience. It was incredibly educational and provided me with
            hands-on insights into the practical applications of my studies.
          </p>
          <h2>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;(You Can find Internship Certification in
            the certifications section){" "}
          </h2>
        </div>
      </Content_Left>

      <div style={{ width: "100%", padding: "20px", background: "#000" }}>
        <span className="expression">
          <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
          Perhaps not the top candidate, but I compensate with my hard work and
          dedication to the projects I work on
        </span>
      </div>
      <div className="footerBackgroundExperience">
        <Footer />
      </div>
    </div>
  );
};
export default Professionalexperience;

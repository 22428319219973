import React, { useEffect, useState, useContext } from "react";
import handleUserInput from "./HandleUserInput";
import TerminalContext from "./Context/TerminalContext";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

const Instructions = ({ className }) => {
  const navigate = useNavigate();

  const [userinput, setUserInput] = useState("");
  const { screen, changeScreen } = useContext(TerminalContext);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleUserInput(userinput, screen, changeScreen, navigate);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [userinput, screen, changeScreen]);

  const text = [
    "So, what do you want to know about me?",
    "Enter an option from A to L then press Enter",
    "A) Home",
    "B) Resume(There is a copy on my LinkedIn profile, If you were directed by email link the resume is attached to it)",
    "C) Projects",
    "D) Certifications",
    "E) Techonologies familiar with",
    "F) Education",
    "G) Professional Experience",
    "H) Volunteering",
    "I) Other jobs",
    "J) Internship objectives",
    "K) Contact",
    "L) Go back",
    "Your Option:",
  ];
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={className}>
      <Typewriter
        className="temp"
        options={{
          cursor: "~$",
          delay: 0,
          autoStart: true,
          loop: false,
        }}
        onInit={(typewriter) => {
          typewriter.typeString(text[0]);
          typewriter.typeString("<br/><p></p>" + text[1]);
          typewriter.typeString("<br/><p></p>" + text[2]);
          typewriter.typeString("<br/>" + text[3]);
          typewriter.typeString("<br/>" + text[4]);
          typewriter.typeString("<br/>" + text[5]);
          typewriter.typeString("<br/>" + text[6]);
          typewriter.typeString("<br/>" + text[7]);
          typewriter.typeString("<br/>" + text[8]);
          typewriter.typeString("<br/>" + text[9]);
          typewriter.typeString("<br/>" + text[10]);
          typewriter.typeString("<br/>" + text[11]);
          typewriter.typeString("<br/>" + text[12]);
          typewriter.typeString("<br/>" + text[13]);
          typewriter.typeString("<p></p>" + text[14]).start();
        }}
      />
      {isVisible && (
        <input
          autoFocus
          type="text"
          placeholder="(Type Here)"
          value={userinput}
          onChange={(e) => setUserInput(e.target.value)}
        />
      )}{" "}
    </div>
  );
};

export default Instructions;

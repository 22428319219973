import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import logo from "../../public/logo_isi.png";
import isima from "../../public/isima.png";
import logomyschool from "../../public/logomyschool.png";
import educationLogo from "../../public/logo.png";
import Content_Left from "components/Content_Left";
import Content_Right from "components/Content_Right";
import Photoof_Isi from "../../public/isiphoto.jpg";
import isimaPhoto from "../../public/isima.jpg";
import mySchoolPhoto from "../../public/myschool.jpg";
import All_Content_Right from "components/All_Content_Right";
import bac from "../../public/bac.jpg";
import AllContentTop from "components/AllContentTop"
const Education=()=>{
    return(
        <div className="OtherPagesContainer ">
        
          <div className="OtherPagesContainerInnerText sideBar" style={{position:"absolute",zIndex:"99",bottom:"0",left:"10px",width:"30vh"}}>
          <p> </p>

          </div>
          <div className="EducationPageContainerBackground">
          <div className={"pagetTitle"}>
            Education
          </div>
          </div>
          <div className="ImageSpan">
             <span style={{width:"33vh"}}></span>
          <div className="ImageSpanSpans">
             <span><number  className="number">01</number>&nbsp;&nbsp;&nbsp;&nbsp;hire me </span>
          </div>
          </div>


          <Content_Left ClassName={"Isi_Logo"} logo={logo} sourceImage={Photoof_Isi}>

          <div className="OtherPagesContainerInnerText">
          <h1>Bachelor's degree at the Higher Institute of Computer Science Ariana (Tunis El Manar University)</h1>
          <h2>(2020-present)</h2>
          <p>&nbsp;&nbsp;&nbsp;&nbsp; I have managed to complete all theoretical studies in computer systems and network engineering for this degree.</p>
          <p> &nbsp;&nbsp;&nbsp;&nbsp;This particular program, offered at the institute, stands out from the other two degrees as it provides a comprehensive understanding of both networking and software engineering aspects of information technology. It imparts the fundamental knowledge needed for graduates to adapt in any given job. </p>
         
       
          </div> 
          <div className="OtherPagesContainerInnerText">
       
          <p> &nbsp;&nbsp;&nbsp;&nbsp;Some of the courses I completed during this program, in addition to mathematics and electronic functions, include Cybersecurity and Pentesting, Networking (CCNA), Linux, Cloud Computing, Systems and Databases Administration, 5G, IoT, Big Data, Artificial Intelligence, Machine Learning, and Application Development (Desktop/Web/Mobile). Furthermore, I gained proficiency in various programming languages such as C, Java, and Python during my time at the institute.</p>
       
          </div>        

          </Content_Left>
        


          <All_Content_Right ClassName={"isima"} logo={isima} sourceImage={isimaPhoto} Title={"Before Tunis El Manar, I was enrolled at the Higher Institute of Computer Science in Mahdia (Monastir University)"}>
            

          <div className="OtherPagesContainerInnerText">
          <h2>(2019-2020)</h2>
          <p>As my Initiating year in University, it proved to be one of the most enriching and transformative experiences of my life. I was fortunate to attend a recently established institute (the building was completed and inaugurated in 2017.), where I had the opportunity to interact with individuals from various regions of Tunisia and Africa as a whole. The labs were outfitted with state-of-the-art equipment and supported by highly capable staff. The overall student experience was exceptionally gratifying.  </p>
          <p> In terms of academic content, I studied essential topics such as data structures, pointers, algorithmics, recursion, loops, and conditional statements in the C programming language.</p>
       
          </div>
      
          </All_Content_Right>       

          <Content_Right ClassName={"logomyschool"} logo={logomyschool} sourceImage={mySchoolPhoto
          }>
        

          <div className="OtherPagesContainerInnerText">
          <h1>One Month Of Accelerated courses in Algorithmics</h1>
          <h2>(2018)</h2>

          </div> 
        <div className="OtherPagesContainerInnerText">
         
          <p>For 27 consecutive days, 55 hours, we delve into functional programming. We tackled a range of problems including Palindrome Checker, Reverse a String, Fibonacci Sequence, Prime Number Checker, Merge Sort, Quick Sort, and more. With each problem, I explored various solutions with the group I am in. In the process of grasping the fundamentals of coding, I encountered my fair share of repeated mistakes. Surprisingly, this led me to inadvertently discover solutions to other problems. This intensive month proved to be immensely rewarding, serving as a catalyst for my shift from a background in biology and physics to the realm of information technology.</p>
        </div>          

          </Content_Right>
        

        <div style={{width:"100%",heigt:"auto",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
          <div style={{width:"25%"}}>

          </div>
          <div className="OtherPagesContainerInnerText" style={{width:"75%",height:"auto",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",padding:"20px"}}>
            <h1>Baccalaureate Diploma in Biology and Physics Sciences, 2019.</h1>
            <img src={bac}style={{width:"100%",height:"auto"}}></img>
          </div>    
        </div>
      
    <div style={{width:"100%",padding:"20px",background:"#000"}} >
        <span  className="expression"><number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;18 years, over 30000 hours, thousands of papers, books and notebooks, over 90 professors, 5 educational institues culminate in this moment.</span>
       
        </div>
   <div className="footerBackground">
        <Footer  />
        </div>
      </div>
    )
}
export default Education;

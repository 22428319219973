import React from "react";
import Footer from "components/Footer";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
const Resume = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <div className="ResumeContainer">
      <div className="filter">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 100,
                  duration: 0.7,
                },
              },
            },
            particles: {
              color: {
                value: "#fff",
              },
              links: {
                color: "#fff",
                distance: 100,
                enable: true,
                opacity: 0.1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: true,
                speed: 0.4,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 500,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 0.1, max: 2 },
              },
            },
            detectRetina: true,
          }}
        />
        <Parallax className="ResumeCentralContainer"  pages={15}>
    
          <ParallaxLayer offset={0} speed={1.2} className="ResumeSmallerContainers">
            {/*******************************Resume Introduction************** */}
            <div className="ResumePageHeader">My Resume</div>
            <div className="Introduction">
              <h1>Who am I</h1>
              <div className="ResumeContentConainer">
                <p>
                  A third-year bachelor's student in computer engineering at the
                  Higher Institute of Computer Science in Ariana, Tunis Elmanar.
                  I'm just getting started in web and mobile development and
                  have been learning JavaScript, Node.Js, React.Js, React
                  Native, and VueJs through hands-on projects. I'm passionate about building things
                  that matter and am excited about the chance to grow in this
                  field.
                </p>
                
              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer sticky={{ start: 1.1, end: 6}} offset={1}  speed={1.2} className=" ResumeSmallerContainers">
          
          <div className="font" >Projects</div>
         
          </ParallaxLayer>

          <ParallaxLayer offset={1.3}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">

          <h2>
                 1/ Post Editing and Content Managing Dashboard for a Blog in
                  ReactJs:
                </h2>
                <p>
                  <span>Features:</span> <br></br> Authentication using JWT, dark mode,
                  and light mode themes. Dynamic content editor tailored to
                  administrators' needs, with varying inputs, layouts, and types
                  of media. Each blog section corresponds to a dedicated
                  dashboard section.
                </p>
                </div>
          </ParallaxLayer>

          <ParallaxLayer offset={1.8}  speed={1.3} className="ResumeSmallerContainers">
          <div className="contentOfResmue">

          <p>
                  <span>Hooks Used:</span><br></br> useState, useEffect, useContext,
                  useMemo.
                </p>
                <p>
                  <span>State Management:</span><br></br> Redux.
                </p>
                <p>
                  <span>Routing:</span><br></br> React-router-dom.
                </p>
                <p>
                  <span>Dependencies:</span><br></br> react-pro-sidebar, formik, and yup
                  for form handling.
                </p>
                <p>
                  The dashboard is designed to centralize blog content in a
                  single location. If you want to know further details about the
                  project visit projects section.
                </p>
</div>              
          </ParallaxLayer>

          <ParallaxLayer offset={2.1}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">
                <h2>
                  Responsive Full-Stack Blog in VueJs (later migrated to NuxtJS)
                  and Node.js Express:
                </h2>
                <p>
                  The dashboard is designed to centralize blog content in a
                  single location.If you want to know further details about the
                  project visit projects section.
                </p>
                <p>
                  <span>Backend Middleware:</span> JWT.
                </p>
                <p>
                  <span>Databases:</span> Combination of MySQL and MongoDB,
                  working alongside Firebase Storage for organizing different
                  blog elements.
                </p>
                <p>
                  <span>Dependencies: </span> -slick-carousel, axios.
                </p>
              </div>
             <div></div>
          </ParallaxLayer>

          <ParallaxLayer offset={3.1}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">
                {" "}
                <h2>Simple Django and Bootstrap Blog with Authentication.</h2>
              </div>
          </ParallaxLayer>

          <ParallaxLayer offset={4.1}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">
                <h2>Landing Page for a Residential Electrician</h2>
                <p>Simple Template customisation.</p>
                <p>
                  <span>Template used:</span>Trydo react template
                </p>
              </div>
          </ParallaxLayer>

          <ParallaxLayer offset={5.1}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">
                <h2>HTML CSS Projects for FreeCodeCamp Certificates:</h2>
                <p>
                  Projects can be found in the certificate section.(login page,
                  tribute page, documentation page, landing page, personal
                  portfolio)
                </p>
          

              </div>
          </ParallaxLayer>
          
          <ParallaxLayer offset={5.8}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">
              
                <h2>This simple portfolio in ReactJs</h2>

              </div>
          </ParallaxLayer>
          
          <ParallaxLayer offset={5.9}  speed={1.2} className="ResumeSmallerContainers">
          <div className="contentOfResmue">
         
                <h2>Mobile App design(named sesame) in Figma </h2>

              </div>
          </ParallaxLayer>
          <ParallaxLayer offset={7}  speed={1.5} className="ResumeSmallerContainers">
          <div className="font" >Experience</div>
          
          </ParallaxLayer>
          <ParallaxLayer offset={7}  speed={0.9} className="ResumeSmallerContainers">
          
      
            <div className="contentOfResmue">
                <h2>One-month internship at the Bank of Tunisia.(2020)</h2>
                <p>
                  Engaged in a variety of tasks, including hardware maintenance,
                  network and database administration.{" "}
                </p>
              </div>
        
          </ParallaxLayer>
          <ParallaxLayer offset={8} sticky={{ start: 8, end: 11}}  speed={1.5} className="ResumeSmallerContainers">
          <div className="font" >Education</div>
          
          </ParallaxLayer>
          <ParallaxLayer offset={8} className="ResumeSmallerContainers"  speed={1.5} >
     
              <div className="contentOfResmue">
                <h2>
                  Bachelor's degree at the Higher Institute of Computer Science
                  Ariana (Tunis El Manar University)(2020-present)
                </h2>
                <p>
                  {" "}
                  I have managed to complete all theoretical studies in computer
                  systems and network engineering for this degree.{" "}
                </p>
                <p>
                  {" "}
                  <span>Courses: </span>Cybersecurity and Pentesting, Networking
                  (CCNA), Linux, Cloud Computing, Systems and Databases
                  Administration, 5G, IoT, Big Data, Artificial Intelligence,
                  Machine Learning, and Application Development
                  (Desktop/Web/Mobile).{" "}
                </p>
           
             
              </div>
            
          </ParallaxLayer>
          <ParallaxLayer offset={9} className="ResumeSmallerContainers"  speed={1.5} >
     
     <div className="contentOfResmue">
      
  
       <h2>
         {" "}
         the Higher Institute of Computer Science in Mahdia (Monastir
         University)(2019-2020)
       </h2>
       <p>
         {" "}
         In terms of academic content, I studied essential topics such
         as data structures, pointers, algorithmics, recursion, loops,
         and conditional statements in the C programming language.
       </p>

      
     </div>
   
 </ParallaxLayer>
 <ParallaxLayer offset={10} className="ResumeSmallerContainers"  speed={1.5} >
     
     <div className="contentOfResmue">
    

       <h2>One Month Of Accelerated courses in Algorithmics(2018)</h2>
       <p>
         {" "}
         For 27 consecutive days, 55 hours, we delve into functional
         programming. We tackled a range of problems including
         Palindrome Checker, Reverse a String, Fibonacci Sequence,
         Prime Number Checker, Merge Sort, Quick Sort.
       </p>
   
    
     </div>
   
 </ParallaxLayer>
 <ParallaxLayer offset={11} className="ResumeSmallerContainers"  speed={1.5} >
     
     <div className="contentOfResmue">
     
   
       <h2>
         Baccalaureate Diploma in Sciences( Biology, Physics, chemistry
         and mathematic)(2019)
       </h2>
     </div>
   
 </ParallaxLayer>
 <ParallaxLayer offset={12}  speed={1.5} className="ResumeSmallerContainers">
          <div className="font" >extracurricular<br>
          </br>activities</div>
          
          </ParallaxLayer>
          <ParallaxLayer offset={12} speed={0.9} className="ResumeSmallerContainers">
       
              <div className="contentOfResmue">
                <h2>
                  Managerial position in the team Manager department at the
                  Creative Lab ISI
                </h2>
                <p>
                  {" "}
                  <span> Mission: </span> Administration human resources,
                  coordinating logistics, managing projects, events, and
                  information sessions, and supervising participants.
                </p>
       
                <h2> Member of Free ways ISI</h2>
          
                <h2> Member of securinets ISI</h2>
              </div>
           
          </ParallaxLayer>
          <ParallaxLayer offset={13}  speed={1.5} className="ResumeSmallerContainers">
          <div className="font" >Certificates</div>
          
          </ParallaxLayer>
       

          <ParallaxLayer offset={13} speed={0.9} className="ResumeSmallerContainers">
        
              <div className="contentOfResmue">
                <h2>FreeCodeCamp:Responsive Web Design</h2>
                <p>
                  300 hours to immersive learning. This extensive program covers
                  HTML, CSS, and responsive design principles.
                </p>
            
                <h2>FreeCodeCamp:JavaScript Algorithms and Data Structures</h2>
                <p>
                  fundamentals of JavaScript(OOP and FP) including variables,
                  objects, loops, and functions.
                </p>
              </div>
          
          </ParallaxLayer>

          <ParallaxLayer offset={13.9} className="ResumeSmallerContainers">
          <div className="font" ><Footer/></div>
          </ParallaxLayer>


        </Parallax>
      </div>
    </div>
  );
};
export default Resume;

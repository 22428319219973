import React from "react";
import Typewriter from 'typewriter-effect';

const Loading = ({className}) => {

    
    



    return (
        <div className={className}>
        <div>Thank you for choosing, you are being directedù! let's give it a chance ^^</div>
       <div className="LoadingTypewriter">
        <Typewriter
        options={{
            cursor: ":~$",
            delay: 0,
            autoStart: true,
            loop:false,
          }}  
          onInit={(typewriter) => {
            typewriter.typeString("#############################################################################")
           .pauseFor(0)
            .start();
          }}
        /></div>

</div>
   
      
    )
}

export default Loading;

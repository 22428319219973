import React,{useContext} from "react";
import { Sidebar, Menu  } from "react-pro-sidebar";
import SideBarContext from "./SideBarContext/SideBarContext";
import sidebar from "../public/sidebar.png";
import totheleft from "../public/icons/totheleft.png";
import certification from "../public/icons/certifications.png";
import technologies from "../public/icons/technology.png"
import contact from "../public/icons/contact.png";
import education from "../public/icons/education.png";
import experience from "../public/icons/experience.png";
import parttime from "../public/icons/parttime.png";
import goal from "../public/icons/goal.png";
import projects from "../public/icons/projects.png";
import resume from "../public/icons/resume.png";
import volunteering from "../public/icons/volunteering.png";
import "../public/styles.css";
import { useNavigate } from "react-router-dom";
const Navigation = () => {
  const na = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);
  const { toggled, toggle } = useContext(SideBarContext);


  return (
    <div className="SiderBarContainer">
  
      <img
        src={sidebar}
        className={`sidebar-image ${collapsed ? "collapsed" : ""}`}
      ></img>
      <div className="sidebarContainer">
        <Sidebar
          collapsedWidth="4vw"
          width="17vw"
          backgroundColor="#4801FF"
          collapsed={collapsed}
          style={{ height: "100vh", borderRightWidth: "0" }}
          transitionDuration={300}
        >
          <Menu >
       
            <div
          onClick={() => {setCollapsed(!collapsed); toggle(!toggled);console.log("state", toggled);
          }}
          style={{marginTop:"20px"}}
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Collaapse">
                    Collapse
                  </div>
                </span>
              )}
              {collapsed ? (
                <div>
                  <img
                    src={totheleft}
                    style={{
                      width: "25px",
                      margin: "2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>
            <div
              onClick={() => {
                na("/resume");
              }}
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Resume">
                    Resume
                  </div>
                </span>
              )}
              {collapsed ? (
                <div>
                  <img
                    src={resume}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/projects");
              }}
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Projects">
                    {" "}
                    Projects
                  </div>
                </span>
              )}
                      {collapsed ? (
                <div>
                  <img
                    src={projects}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/certifications");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Certifications">
                    {" "}
                    Certifications
                  </div>
                </span>
              )}
                      {collapsed ? (
                <div>
                  <img
                    src={certification}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/technologies");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Techonologies">
                    {" "}
                    Techonologies
                  </div>
                </span>
              )}
                      {collapsed ? (
                <div>
                  <img
                    src={technologies}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/education");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Education">
                    {" "}
                    Education
                  </div>
                </span>
              )}
               {collapsed ? (
                <div>
                  <img
                    src={education}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/professional-experience");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Experience">
                    {" "}
                    Experience
                  </div>
                </span>
              )}
               {collapsed ? (
                <div>
                  <img
                    src={experience}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/volunteering");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Volunteering">
                    {" "}
                    Volunteering
                  </div>
                </span>
              )}
               {collapsed ? (
                <div>
                  <img
                    src={volunteering}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/other-jobs");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Other&nbsp;jobs">
                    {" "}
                    Other&nbsp;jobs
                  </div>
                </span>
              )}
               {collapsed ? (
                <div>
                  <img
                    src={parttime}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/internship-objectives");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Objectives">
                    Objectives
                  </div>
                </span>
              )}
               {collapsed ? (
                <div>
                  <img
                    src={goal}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>

            <div
              onClick={() => {
                na("/contact");
              }}
              
            >
              {!collapsed && (
                <span class="container">
                  <div class="text" data-text="Contact">
                    {" "}
                    Contact
                  </div>
                </span>
              )} {collapsed ? (
                <div>
                  <img
                    src={contact}
                    style={{
                      width: "25px",
                      margin: "1vh 2vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : undefined}
            </div>
          </Menu>
        </Sidebar>
      </div>{" "}
    </div>
  );
};
export default Navigation;

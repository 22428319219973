import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import Content_Left from "components/Content_Left";
import bank_photo from "../../public//bank.jpg";

const Goals = () => {
  return (
    <div className="OtherPagesContainer ">
      <div
        className="OtherPagesContainerInnerText sideBar"
        style={{
          position: "absolute",
          zIndex: "99",
          bottom: "0",
          left: "10px",
          width: "30vh",
        }}
      >
        <p> </p>
      </div>
      <div className="Goals">
        <div className={"pagetTitle"}>Future<br></br>Endeavors</div>
      </div>
      <div className="ImageSpan">
        <span style={{ width: "33vh" }}></span>
        <div className="ImageSpanSpans">
          <span>
            <number className="number">01</number>&nbsp;&nbsp;&nbsp;&nbsp;hire
            me{" "}
          </span>
        </div>
      </div>
      <div className="GoalsContainer">
    <div style={{width:"25%"}}></div>
    <div style={{width:"75%",height:"auto"}}>
    <div className="OtherPagesContainerInnerText">
<h1>To be concise</h1>
</div>
    <div className="OtherPagesContainerInnerTextGoals">
    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberTitle">01</number> <h1>I am eager to secure a paid internship in a dynamic and educational environment.</h1></div>
        <h2 >I seek mentorship from experienced professionals to enhance my skills in:</h2>
        <div style={{ marginTop:"20px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberObjctive">01</number> <p>Full Stack Mobile/Web Development</p></div>
        <div style={{ marginTop:"20px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberObjctive">02</number><p>Scalable Backend Development</p></div>
        <div style={{ marginTop:"20px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberObjctive">03</number><p>Software Engineering</p></div>
        <div style={{ marginTop:"20px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberObjctive">04</number><p>Artificial Intelligenc and Machine Learning Solutions</p></div>
        <div style={{  marginTop:"20px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberObjctive">05</number><p>Scalable Systems/Solution/Infrastructure  Architect</p></div>
        <div style={{  marginTop:"20px",marginBottom:"20px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberObjctive">06</number><p>Software systems design</p></div>


</div>

<div className="OtherPagesContainerInnerTextGoals">
<div style={{ marginTop:"60px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberTitle">02</number> <h1>Hopefully, I could finish the internship project with the utmost efficiency. Prioritizing both speed and quality, my foremost goal is to seize the opportunity to rectify any past oversights and embrace the chance to acquire new knowledge, particularly in understanding new tech trends and market dynamics.</h1></div>
</div>
<div className="OtherPagesContainerInnerTextGoals">
<div style={{marginTop:"60px",display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start"}}><number className="numberTitle">03</number> <h1>Upon completing the project, my final milestone for next six months is to conclude my internship by securing a job that not only promises financial rewards but, more importantly, holds great significance for my burgeoning career. This position would provide opportunities for growth in both technical expertise and professional development.</h1></div>
     

</div>
</div>
   </div>
   
      <Content_Left ClassName={"bank"} bancSource={bank_photo}>
     <></>
   
      </Content_Left>

      <div style={{ width: "100%", padding: "20px", background: "#000" }}>
        <span className="expression">
          <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
          It's not 'going to the space' type of goals, but for the time being, I am settling for those shorter-term goals       </span>
      </div>
      <div className="footerGoals">
        <Footer />
      </div>
    </div>
  );
};
export default Goals;

import React from "react";
import Footer from "components/Footer";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

import github from "../../public/icons/github.png";
import mailwhite from "../../public/icons/mailwhite.png";
import phonewhite from "../../public/icons/phonewhite.png";
import LinkedIn from "../../public/icons/linkedinwhite.png";

const Resume = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <div className="ContactContainer">
      <div className="filter">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 100,
                  duration: 0.7,
                },
              },
            },
            particles: {
              color: {
                value: "#fff",
              },
              links: {
                color: "#fff",
                distance: 100,
                enable: true,
                opacity: 0.1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: true,
                speed: 0.4,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 500,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 0.1, max: 2 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className="technologiesContainerInside">
          <div className="technologiesHeader">
            Contact
          </div>
          <div className="technologies">     <div className="technologiesCategorie">        <div className="contactPage ocuspotus">
           
            <p>
              <img src={mailwhite}></img>
skander.nefli@etudiant-isi.utm.tn
            </p>
            <p>
              <img src={mailwhite}></img>
skander.nefli@gmail.com
            </p>
            <p>
              <img src={phonewhite}></img>
21067995            </p>
<p>
              <img src={LinkedIn}></img>
Skander Nefli            </p>
<p>
              <img src={github}></img>
skander.nefli            </p>

          </div></div></div>


        </div>
      </div>
    </div>
  );
};
export default Resume;

import React from "react";
import Footer from "components/Footer";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
const Resume = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <div className="technologiesContainer">
      <div className="filter">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 100,
                  duration: 0.7,
                },
              },
            },
            particles: {
              color: {
                value: "#fff",
              },
              links: {
                color: "#fff",
                distance: 100,
                enable: true,
                opacity: 0.1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: true,
                speed: 0.4,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 500,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 0.1, max: 2 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className="technologiesContainerInside">
          <div className="technologiesHeader">
            Technologies
          </div>
          <div className="technologies">
            <div className="technologiesCategorie">
            <div className="technologiesCategoryName"><span>Front end</span> <div className="levelcontainer"><div className="Front" ></div></div></div>
            <div className="technologiesCategoryName"><span>ReactJs</span> <div className="levelcontainer"><div className="ReactJs" ></div></div></div>
            <div className="technologiesCategoryName"><span>VueJs </span> <div className="levelcontainer"><div className="VueJs" ></div></div></div>
            <div className="technologiesCategoryName"><span>NuxtJs</span> <div className="levelcontainer"><div className="NuxtJs" ></div></div></div>

            
            </div>
            <div className="technologiesCategorie">
            
            <div className="technologiesCategoryName"><span>Back end</span> <div className="levelcontainer"><div className="backendLevel" ></div></div></div>
            <div className="technologiesCategoryName"><span>NodeJS</span> <div className="levelcontainer"><div className="NodeJS" ></div></div></div>
            <div className="technologiesCategoryName"><span>MongoDB </span> <div className="levelcontainer"><div className="MongoDB" ></div></div></div>
            <div className="technologiesCategoryName"><span>MySQL</span> <div className="levelcontainer"><div className="MySQL" ></div></div></div>

            
            </div>
            <div className="technologiesCategorie">
            <div className="technologiesCategoryName"><span>Cloud</span> <div className="levelcontainer"><div className="Cloud" ></div></div></div>
            <div className="technologiesCategoryName"><span>AWS</span> <div className="levelcontainer"><div className="AWS" ></div></div></div>
            <div className="technologiesCategoryName"><span>Azure/IBM </span> <div className="levelcontainer"><div className="Azure" ></div></div></div>
            <div className="technologiesCategoryName"><span>Google</span> <div className="levelcontainer"><div className="Google" ></div></div></div>

            </div>
            <div className="technologiesCategorie">
            <div className="technologiesCategoryName"><span>Seo</span> <div className="levelcontainer"><div className="Seo" ></div></div></div>
            <div className="technologiesCategoryName"><span>Screaming Frog</span> <div className="levelcontainer"><div className="Google" ></div></div></div>
            <div className="technologiesCategoryName"><span>similarweb</span> <div className="levelcontainer"><div className="Google" ></div></div></div>
            <div className="technologiesCategoryName"><span>ChatGPT</span> <div className="levelcontainer"><div className="Google" ></div></div></div>

            </div>
            <div className="technologiesCategorie">
            <div className="technologiesCategoryName"><span>Design</span> <div className="levelcontainer"><div className="Front" ></div></div></div>
            <div className="technologiesCategoryName"><span>Figma</span> <div className="levelcontainer"><div className="ReactJs" ></div></div></div>
            <div className="technologiesCategoryName"><span>Adobe: <br></br>Photoshop<br></br>Illustrator<br></br>Premiere 2020 </span> <div className="levelcontainer"><div className="VueJs" ></div></div></div>
            <div className="technologiesCategoryName"><span>Presentation tools<br></br>(power point,<br></br> prezzi,<br></br> mindmap)</span> <div className="levelcontainer"><div className="NuxtJs" ></div></div></div>

            
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};
export default Resume;

import React, { useState } from "react";
import TerminalContext from "./TerminalContext";

const TerminalContextProvider = ({ children }) => {
    const [screen, setScreen] = useState("welcome");

    const changeScreen = (newScreen) => {
        setScreen(newScreen);
    }

    return (
        <TerminalContext.Provider value={{ screen, changeScreen }}>
            {children}
        </TerminalContext.Provider>
    );
}

export default TerminalContextProvider;
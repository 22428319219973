import React, { useState, useEffect, useContext } from "react";
import SideBarContext from "components/SideBarContext/SideBarContext";
import InitialLoader from "./components/Loader/InitialLoader";
import { Routes, Route, useLocation,useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive"; // Import the useMediaQuery hook
import Terminal from "./scenes/Terminal/Terminal";
import Certifications from "./scenes/Certifications/Certifications";
import Contact from "./scenes/Contact/Contact";
import Education from "./scenes/Education/Education";
import Goals from "./scenes/Goals/Goals";
import Landing from "./scenes/Landingpage/Landing";
import Parttimejobs from "./scenes/Parttimejobs/Parttimejobs";
import Professionalexperience from "./scenes/Professionalexperience/Professionalexperience";
import Projects from "./scenes/Projects/Projects";
import Resume from "./scenes/Resume/Resume";
import Technologies from "./scenes/Technologies/Technologies";
import Volunteering from "./scenes/Volunteering/Volunteering";
import Navigation from "./components/Navigation";
import NavigationCard from "./components/NavigationCard";
import ResponsiveError from "scenes/Error/ResponsiveError";
import "./public/styles.css";
import NotFOund from "scenes/Error/NotFOund";

function App() {
  const { toggled, toggle } = useContext(SideBarContext);
const navigate=useNavigate();
  const location = useLocation();
  console.log(location);

  const [isSideBar, setIsSideBar] = useState(false);
  const [isWhite, setIsWhite] = useState(false);
  const [loading, setLoading] = useState(true);

  const isSmallScreen = useMediaQuery({ maxWidth: 900 }); 

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "r") {
        navigate("/");
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate]);

  useEffect(() => {
    setIsSideBar(location.pathname !== "/");
    setIsWhite(location.pathname !== "/home");
  }, [location.pathname]);

  if (loading) {
    return <InitialLoader />;
  }

  const Sidecontainer = ({ children }) => {
    return (
      <div
        className={`appContainer ${
          (toggled && "appContainerCollapse") ||
          (!isSideBar && "appContainerNoSideBar")
        }  ${(isWhite && "white")}`}
      >
        {children}
      </div>
    );
  };

  return (
    <div className={`appContainerglobal ${isWhite ? "" : "white"}`}>
      {isSmallScreen && <ResponsiveError />}
        {isSideBar && <NavigationCard />}
<Sidecontainer>
          {" "}
          <Routes>
            <Route path="/" element={<Terminal />} />
            <Route path="/certifications" element={<Certifications />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/education" element={<Education />} />
            <Route path="/internship-objectives" element={<Goals />} />
            <Route path="/home" element={<Landing />} />
            <Route path="/other-jobs" element={<Parttimejobs />} />
            <Route
              path="/professional-experience"
              element={<Professionalexperience />}
            />
            <Route path="/projects" element={<Projects />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/technologies" element={<Technologies />} />
            <Route path="/volunteering" element={<Volunteering />} />
            
            <Route path="*" element={<NotFOund />} />

          </Routes>
      </Sidecontainer>
        {isSideBar && <Navigation />}
      </div>
  );
}

export default App;

import React, { useEffect, useState, useContext } from "react";
import handleUserInput from "./HandleUserInput";
import TerminalContext from "./Context/TerminalContext";
import { useNavigate } from "react-router-dom";

const Instructions = ({ className }) => {
  const navigate = useNavigate();

  const [userinput, setUserInput] = useState("");
  const { screen, changeScreen } = useContext(TerminalContext);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleUserInput(userinput, screen, changeScreen, navigate);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [userinput, screen, changeScreen]);

  const text = [
    "So, what do you want to know about me?",
    "Enter an option from A to L then press Enter",
    "A) Home",
    "B) Resume(There is a copy on my LinkedIn profile, If you were directed by email link the resume is attached to it)",
    "C) Projects",
    "D) Certifications",
    "E) Techonologies familiar with",
    "F) Education",
    "G) Professional Experience",
    "H) Volunteering",
    "I) Other jobs",
    "J) Internship objectives",
    "K) Contact",
    "L) Go back",
    "Your Option:",
  ];

  return (
    <div className={className}>
      <span>
        <div>So, what do you want to know about me?</div>
        <div >
          <br />
          Enter an option from A to L then press Enter
        </div>
        <div>
          <br />
          A) Home
        </div>
        <div>
          B) Resume(There is a copy on my LinkedIn profile, If you were directed
          by email link the resume is attached to it)
        </div>
        <div>C) Projects</div>
        <div>D) Certifications</div>
        <div>E) Techonologies familiar with</div>
        <div>F) Education</div>
        <div>G) Professional Experience</div>
        <div>H) Volunteering</div>
        <div>I) Other jobs</div>
        <div>J) Internship objectives </div>
        <div>K) Contact</div>
        <div>L) Go back</div>
      
          <div className="Error">
          <br />
          Error: Please enter an option from A to L then press Enter:~$
        </div>        
      </span>

      <input
        autoFocus
        type="text"
        placeholder="(Type Here)"
        value={userinput}
        onChange={(e) => setUserInput(e.target.value)}
      />
    </div>
  );
};

export default Instructions;

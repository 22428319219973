import React from "react";
import mobileError from "../../public/MobileError.png"
import "../../public/styles.css"
const ResponsiveError=()=>{
return(
    <div className="MobileError">
        <div className="responsiveerror">
        Oops! 🤖 We're working hard to make this site look stunning on your phone, but we haven't quite gotten there yet. Our sincerest apologies for any inconvenience. A fully responsive design is in the works and will be live soon! In the meantime, feel free to explore on a larger screen for the best experience. Thank you for your understanding.         </div>
        <img src={mobileError} style={{width:"100%",height:"auto", position:"absolute", zIndex:"-999",bottom:"10%"}}></img>
    </div>
)
}
export default ResponsiveError;
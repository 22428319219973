import React, { useRef ,useEffect,useState} from "react";
import Header from "../../components/Header";
import "../../public/styles.css";
import "../../public/Styles.scss";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import reactlogo from "../../public/icons/reactjs.png";
import aws from "../../public/icons/aws.png";
import css from "../../public/icons/css.png";
import mongo from "../../public/icons/mongo.png";
import mysql from "../../public/icons/mysql.png";
import nodejs from "../../public/icons/nodejs.png";
import nuxtjs from "../../public/icons/nuxtjs.png";
import vuejs from "../../public/icons/vuejs.png";
import github from "../../public/icons/github.png";
import mailwhite from "../../public/icons/mailwhite.png";
import phonewhite from "../../public/icons/phonewhite.png";
import LinkedIn from "../../public/icons/linkedinwhite.png";
import mockupLandingPage1 from "../../public/mockupLandingPage1.png";
import mockupLandingPage3 from "../../public/mockupLandingPage3.png";
import background from "../../public/section1bacground.mp4";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const Navigate = useNavigate();
  const parallax = useRef<IParallax>(null!);
  const [offsetState, setCurrentOffset] = useState<number>(0);

 
  const handleDotClick = (offset: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(offset);
      setCurrentOffset(offset);
    }
  };


  return (
    <div className="LandingPage">
      <Header />
      <div className="dotContainer">
      <div className={`dot ${offsetState === 0 ? 'activeDot' : ''}`}  onClick={() => handleDotClick(0)}></div>
        <div className={`dot ${offsetState === 1 ? 'activeDot' : ''}`}  onClick={() => handleDotClick(1)}></div>
        <div className={`dot ${offsetState === 2 ? 'activeDot' : ''}`}  onClick={() => handleDotClick(2)}></div>
        <div className={`dot ${offsetState === 3 ? 'activeDot' : ''}`}  onClick={() => handleDotClick(3)}></div>
        <div className={`dot ${offsetState === 4 ? 'activeDot' : ''}`}  onClick={() => handleDotClick(4)}></div>
        <div className={`dot ${offsetState === 5 ? 'activeDot' : ''}`}  onClick={() => handleDotClick(5)}></div>
        </div>
      <Parallax ref={parallax} pages={6}>
  
        <ParallaxLayer offset={0} speed={1} className={"placeHolder"}>
          <div></div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0}
          onClick={() => parallax.current.scrollTo(0)}
          className={"Cover"}
        >
          <div className={"SectionCover1"}>
            <video autoPlay muted loop id={"backgroundVideo"}>
              <source src={background} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={"Technologies"}>
              <div className="Technology">
                <img src={aws} className={"TechnologyLogo"} />
              </div>
              <div className="Technology">
                <img src={mongo} className={"TechnologyLogo"} />
                <span className={"TechnologySpan"}>MongoDB</span>
              </div>
              <div className="Technology">
                <img src={mysql} className={"TechnologyLogo"} />
                <span className={"TechnologySpan"}>MySQL</span>
              </div>
              <div className="Technology">
                <img src={nodejs} className={"TechnologyLogo"} />
                <span className={"TechnologySpan"}>NodeJS</span>
              </div>
              <div className="Technology">
                <img src={css} className={"TechnologyLogo"} />
              </div>{" "}
              <div className="Technology">
                <img src={reactlogo} className={"TechnologyLogo"} />
                <span className={"TechnologySpan"}>ReactJs</span>
              </div>
              <div className="Technology">
                <img src={vuejs} className={"TechnologyLogo"} />
              </div>
              <div className="Technology">
                <img src={nuxtjs} className={"TechnologyLogo"} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={1.2} className={"Cover2"}>
          <div className={"SectionCover2"}></div>
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={1.2} className={"Cover3"}>
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={2}
          speed={-3.2}
          className={"Cover4"}
        >
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.05} speed={1.8} className="iphoneOne">
          <div  onClick={() => {
            Navigate("/projects");
          }} > 
            <img src={mockupLandingPage1} className="iphone iphonemarin1"></img>
          </div>
        </ParallaxLayer>
       
        <ParallaxLayer offset={2.4} speed={3} className="iphoneThree" >
          <div onClick={() => {
            Navigate("/projects");
          }} >
          <img src={mockupLandingPage3} className="iphone iphonemarin3"></img>
          </div>

        </ParallaxLayer>
        
       



        <ParallaxLayer offset={3} speed={4} className={"ParallaxProjects2"}>
          <div className={"SectionCover2"}></div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={4} className={"ParallaxProjects3"}>
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={3}
          speed={-3.2}
          className={"ParallaxProjects4"}
        >
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={4} speed={4} className={"ParallaxProjects21"}>
          <div className={"SectionCover2"}></div>
        </ParallaxLayer>
        <ParallaxLayer offset={4} speed={4} className={"ParallaxProjects31"}>
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={4}
          speed={-3.2}
          className={"ParallaxProjects41"}
        >
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={5} speed={4} className={"ParallaxProjects211"}>
          <div className={"SectionCover2"}></div>
        </ParallaxLayer>
        <ParallaxLayer offset={5} speed={2} className={"ParallaxProjects311"}>
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={5}
          speed={-3.2}
          className={"ParallaxProjects411"}
        >
          <div className={"SectionCover3"}></div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={2.3}
          speed={3.2}
          className={"SectionHeaderLayout"}
        >
          <div className="IndividualProjectHeaderTextLanding">
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">01</span>functional dashboard 
            </h1>
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">02</span>Anguidehub
            </h1>
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">03</span>CerebrosElec
            </h1>
         
          </div>
        </ParallaxLayer>
       
        <ParallaxLayer
          offset={2.1}
          speed={3.2}
          className={"SectionHeaderLayout"}
        >
          <div className={"SectionHeader3"} onClick={()=>{Navigate("/projects")}}>
            <h1>
              Projects
              <span>Projects</span>
              <span>Projects</span>
              <span>Click For More</span>
            </h1>
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={1.4}
          speed={-0.3}
          className={"SectionHeaderLayout1"}
        >
          <div className={"SectionHeader1"}>
            <h1>
              Technologies
              <span>Technologies</span>
              <span>Technologies</span>
              <span>Click For More</span>
            </h1>
          </div>
        </ParallaxLayer>
         
        <ParallaxLayer
          offset={3.65}
          speed={3.2}
          className={"SectionHeaderLayout"}
        >
          <div className={"SectionHeader2"} onClick={()=>{Navigate("/certifications")}}>
            <h1>
              About Me
              <span>About Me</span>
              <span>About Me</span>
              <span>See certifications</span>
            </h1>
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={3.65}
          speed={3.2}
          className={"SectionHeaderLayout"}
        >
          <div className="IndividualProjectHeaderTextLanding1">
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">00</span>Let's push our boundaries
            </h1>
            <p>
            Computer engineering student starving to construct robust, scalable real-life software systems. I am addicted to the rush of finishing a new project the best way I can, and though I'm at an entry level, I'm working to improve my skills and enter the master's league in years to come. I hope I can one day create solutions that make a difference in people's lives. It's a stretch, but there's nothing wrong with dreaming, and I'll give it my all, even if success isn't guaranteed.
            </p>
          </div>
        </ParallaxLayer>
       
     
        <ParallaxLayer
          offset={4.65}
          speed={1.2}
          className={"SectionHeaderLayout"}
        >
          <div className={"SectionHeader2"} onClick={()=>{Navigate("/internship-objectives")}}>
            <h1>
              Goals
              <span>Goals</span>
              <span>Goals</span>
              <span>Click For More</span>
            </h1>
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={4.30}
          speed={2.2}
          className={"SectionHeaderLayout"}
        >
          <div className="IndividualProjectHeaderTextLanding2">
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">01</span>"Don't watch the clock; do what it does. Keep going."<br></br> - Sam Levenson
            </h1>
          
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={4.65}
          speed={3.2}
          className={"SectionHeaderLayout"}
        >
          <div className="IndividualProjectHeaderTextLanding3">
           
            <p>
            I'm currently pursuing an enlightening internship with the potential to transition into a job. Other than full-stack development , I'm also interested in designing and implementing scalable, real-life complex software architectures. My past projects have been foundational, and now I'm excited to delve into the next level of computer science and engineering.
            </p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={5.35}
          speed={0.4}
          className={"SectionHeaderLayout"}
        >
          <div className="IndividualProjectHeaderTextLanding3 ocuspotus">
           
            <p>
              <img src={mailwhite}></img>
skander.nefli@etudiant-isi.utm.tn
            </p>
            <p>
              <img src={mailwhite}></img>
skander.nefli@gmail.com
            </p>
            <p>
              <img src={phonewhite}></img>
21067995            </p>
<p>
              <img src={LinkedIn}></img>
Skander Nefli            </p>
<p>
              <img src={github}></img>
skander.nefli            </p>

          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={5.65}
          speed={1.5}
          className={"SectionHeaderLayout"}
        >
          <div className={"SectionHeader2"} style={{zIndex:"999 !important"}}onClick={()=>{Navigate("/contact")}} >
            <h1>
              Contact
              <span>Contact</span>
              <span>Contact</span>
              <span>Click For More</span>
            </h1>
          </div>
        </ParallaxLayer>
     
      </Parallax>
    </div>
  );
};
export default Landing;

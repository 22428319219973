import React from "react";
import Loading from "../../public/Loading.gif";
import "../../public/styles.css";
const InitialLoader = () => {
   
 
 
    return (
    <div
     className="InitialLoaderContainer"
    >
      <img src={Loading} alt="loading" className="Loader"  />
      <div>{'<Loading.../>'}</div>
    
    </div>
  );
};

export default InitialLoader;

import React, { useContext } from "react";
import TerminalContext from "./Context/TerminalContext";
import TerminalContextProvider from "./Context/TerminalContextProvider";
import Loading from "./Loading";
import Welcome from "./Welcome";
import Instructions from "./Instructions";
import Wronginput from "./Wronginput";
import "../../public/styles.css";
import profile from "../../public/profile.png";
const Terminal = ({className}) => {
    const { screen, changeScreen } = useContext(TerminalContext);

    return (
        <div className={className[0]} >
            {screen === "welcome" && <Welcome className={className[1]} />}
            {screen === "instructions" && <Instructions className={className[1]} />}
            {screen === "wronginput" && <Wronginput className={className[1]} />}
            {screen === "loading" && <Loading className={className[1]}/>}
        </div>
    )
}

export default () => (
    <TerminalContextProvider >
        <Terminal className={["TerminalPage","Terminal"]}/>
        <div className="ProfileContainer">
        <img src={profile} className="Profile"/>
        </div>
    </TerminalContextProvider>
); 
import React from "react";
import mailIcon from "../public/icons/mailwhite.png";
import phoneIcon from "../public/icons/phonewhite.png";
import linkedIn from "../public/icons/linkedinwhite.png";
import github from "../public/icons/github.png"
const Footer = () => {



  return (
    <div className={"footerContainer"}>
            


      <div className="centralFooter">
        
        <span class="containerfooter">
          <div class="footerText" >
            Skander.nefli@etudiant-isi.utm.tn
          </div>
          <img
            src={mailIcon}
            style={{ width: "20px", height: "20px"}}
          ></img>
        </span>
        <span class="containerfooter">
          <div class="footerText">
            Skander.nefli@gmail.com{" "}
          </div>
          <img src={mailIcon} style={{ width: "20px", height: "20px"}}></img>

        </span>
        <span class="containerfooter">
          <div class="footerText">
            +21621067995{" "}
          </div>
          <img src={phoneIcon} style={{ width: "20px", height: "20px"}}></img>

        </span>
        <span class="containerfooter">
          <div class="footerText" >
            +21651652641{" "}
          </div>
          <img src={phoneIcon} style={{ width: "20px", height: "20px"}}></img>

        </span>
        <span class="containerfooter">
          <div class="footerText" >
            Skander NEFLI
          </div>
          <img src={linkedIn} style={{ width: "20px", height: "20px"}}></img>

        </span>
        <span class="containerfooter">
          <div class="footerText" >
            Skander NEFLI
          </div>
          <img src={github} style={{ width: "20px", height: "20px"}}></img>

        </span>
      </div>

      </div>


  );
};

export default Footer;

import React,{useState,useEffect} from "react";
import TerminalContext from "./Context/TerminalContext";
import Typewriter from 'typewriter-effect';

const Welcome = ({className}) => {
    const { screen, changeScreen } = React.useContext(TerminalContext);

    const handleKeyPress = (event) => {
        if (event.key) {
            event.preventDefault();
            changeScreen("instructions");
            document.removeEventListener('keydown', handleKeyPress);
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []); 
   
    
    const text=["Greetings,","Skander NEFLI, a 3rd-year Bachelor's student specializing in Networking and Computer Systems Engineering at the Higher Institute of Computer Science, Tunis El Manar.","First and foremost, I want to express my gratitude for considering my application. I'm eager to take my first step into the professional world with your respected organization.","Press any key to continue" ];



    return (
        <div className={className}>
        <Typewriter
        options={{
            cursor: ":~$",
            delay: 0,
            autoStart: true,
            loop:false,
          }}  
          onInit={(typewriter) => {
            typewriter.typeString(text[0])
            typewriter.typeString("<p></p><br/>"+text[1])
            typewriter.typeString("<p></p><br/><p></p>"+text[2])
            typewriter.typeString("<p></p><br/><br/><br/><br/><p></p>"+text[3])
            .pauseFor(0)
            .start();
          }}
        />

</div>
   
      
    )
}

export default Welcome;

import React, { useState, useEffect } from "react";
import phone from "../public/icons/phonewhite.png";
import mail from "../public/icons/mailwhite.png";
import linkedin from "../public/icons/linkedinwhite.png";
import phoneBlack from "../public/icons/phoneblack.png";
import mailBlack from "../public/icons/mailblack.png";
import linkedinBlack from "../public/icons/linkedInblack.png";
import TypewriterComponent from "typewriter-effect";
import { useNavigate, useLocation } from "react-router-dom";
const NavigationCard = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const [iswhite, setIswhite] = useState(false);
  useEffect(() => {
    setIswhite(location.pathname === "/home" || location.pathname === "/resume" || location.pathname === "/technologies" || location.pathname === "/contact" );
  }, [location.pathname]);

  const openMail = () => {
    window.open("mailto:skander.nefli@etudiant-isi.utm.tn.com", "_blank");

    const recipientEmail = "mailto:skander.nefli@etudiant-isi.utm.tn.com";
    const subject = encodeURIComponent("Set up an interview");
    const body = encodeURIComponent("Dear skander");
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${subject}&body=${body}`;
    window.open(mailtoLink, "_blank");
  };

  const openPhone = () => {
    window.location.href = "tel:+21621067995";
  };
  const openLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/in/skander-nefli-37191a1a0/",
      "_blank"
    );
  };
 
  return (
    <div className={`navCard ${iswhite ? "" : "dark"}`}>
      <div       onClick={() => {
            Navigate("./technologies");
          }} className={`navCard1 ${iswhite ? "" : "dark"}`}>
        (Skill set)
      </div>
      <div className={`navCard2 ${iswhite ? "" : "dark"}`}>
        <div
          onClick={() => {
            Navigate("./home");
          }}
          className={`skander ${iswhite ? "" : "dark"}`}
        >
          <a>
            <TypewriterComponent
              options={{ strings: ["SKANDER"], autoStart: true, loop: true }}
            />
          </a>
        </div>
        <div
          onClick={() => {
            Navigate("./home");
          }}
          className={`nefli ${iswhite ? "" : "dark"}`}

        >
          <a>NEFLI.</a>
        </div>
        <div
          onClick={() => {
            Navigate("./home");
          }}
          className={`terminal ${iswhite ? "" : "dark"}`}

        >
          <a>Open Terminal(ctrl+Alt+r)</a>
        </div>
      </div>
      <div className={`navCard3 ${iswhite ? "" :"dark"}`}
>
        <div className="contact">
          <img
            onClick={() => {
              openPhone();
            }}
            src={`${iswhite ? phone :phoneBlack}`}
            className="icon"
          ></img>
          <img
            onClick={() => {
              openMail();
            }}
            src={`${iswhite ? mail :mailBlack}`}
            className="icon"
          ></img>
          <img
            onClick={() => {
              openLinkedIn();
            }}
            src={`${iswhite ? linkedin :linkedinBlack}`}
            className="icon"
          ></img>
        </div>
        <div    onClick={() => {
            Navigate("./internship-objectives");
          }}          className={`seeMore ${iswhite ? "white" : "dark"}`}
>
          <a>(See more)</a>
        </div>
      </div>
    </div>
  );
};
export default NavigationCard;

import React from "react";
import Footer from "components/Footer";
import "../../public/styles.css";
import mockup from "../../public/dashboardMockup.png";
import blogmockup from "../../public/BlogMockup.png";
import dashboardEditCategory from "../../public/dashboard.png";
import dashboardAddPost from "../../public/dashboardEditor.png";
import dashboardList from "../../public/dashboardList.png";
import dashboardLogin from "../../public/dashboardLogin.png";
import bloghomepage from "../../public/bloghomepage.png";
import bloghomepage2 from "../../public/bloghomepage2.png";
import blogbuzz from "../../public/blogbuzz.png";
import blogpost from "../../public/blogpost.png";
import blogContact from "../../public/blogContact.png";
import cerebrosElec from "../../public/cererosElec.png";
import crebrosHomePage from "../../public/cerebroshomepage.png";
import leftarrow from "../../public/icons/Long_arrow.png";
import Github from "../../public/github.PNG";
import Diagram from "../../public/diagram.png";
import Carousel from "react-multi-carousel";
import initialDesign from "../../public/InitialDesign.PNG";
import LatestDesign from "../../public/LatestDesign.PNG";


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Projects = () => {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="buttonsOFCAROUSELProject">
        <div className="c">
          <div
            className="CarouselButtonContainerProjects"
            onClick={() => previous()}
          >
            <span>Previous</span>
            <img className="CarouselButton" src={leftarrow} />
          </div>
          <div
            className="CarouselButtonContainerProjects"
            onClick={() => next()}
          >
            <span>Next</span>
            <img className="CarouselButton Right" src={leftarrow} />
          </div>
        </div>
        <div>
        <span className="spanstyleb">
            <span className="numbered">02</span>Solution:
          </span>     <br></br>     <span className="spandstyled">
            The creation of this dashboard serves as a centralized solution to
            streamline and simplify the management of the blog. The primary
            objectives are to enhance reliability, speed, and, most importantly,
            ease of use for tackling the intricate challenges posed by the
            diverse content and functional requirements of the blog.
          </span>
        </div>
      </div>
    );
  };
  const ButtonGroup2 = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="buttonsOFCAROUSELProject">
        <div className="c">
          <div
            className="CarouselButtonContainerProjects"
            onClick={() => previous()}
          >
            <span>Previous</span>
            <img className="CarouselButton" src={leftarrow} />
          </div>
          <div
            className="CarouselButtonContainerProjects"
            onClick={() => next()}
          >
            <span>Next</span>
            <img className="CarouselButton Right" src={leftarrow} />
          </div>
        </div>
        <div>
        <span className="spanstyleb">
            <span className="numbered">02</span>Solution:
          </span>     <br></br>     <span className="spandstyled">
          From the available solutions, I opted for an application serving dual purposes as a blog and an encyclopedia, encompassing over 30 categories featuring both trending and evergreen content. To enhance the blog's interactivity and dynamism, I sought to employ a JavaScript framework. As I intended the project for learning purposes and avoided web content management systems like WordPress, I had to choose between React and Vue.js. Subsequently, I migrated to Nuxt.js, as detailed in the journal section, due to specific reasons.
          </span>
          <br></br><br></br>  <br></br>       <span className="spandstyled">
          The blog's key requirements included mobile-friendliness, dynamism, search engine optimization, and, most importantly, reliability.          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="OtherPagesContainer ">
      <div
        className="OtherPagesContainerInnerText sideBar"
        style={{
          position: "absolute",
          zIndex: "99",
          bottom: "0",
          left: "10px",
          width: "30vh",
        }}
      >
        <p> </p>
      </div>
      <div className="ProjectContainerBackground ">
        <div className={"pagetTitle"}>Projects</div>
      </div>
      <div className="ImageSpan">
        <span style={{ width: "33vh" }}></span>
        <div className="ImageSpanSpans">
          <span>
            <number className="number">01</number>&nbsp;&nbsp;&nbsp;&nbsp;hire
            me{" "}
          </span>
        </div>
      </div>
      <div className="note">
        <span className="numbered">01</span> These projects are created for
        educational purposes <br></br> and are not intended for use in a
        production environment.
      </div>
      <div className="ProjectsInnerContainer">
        <div className="IndividualProjectHeader">
          <img src={mockup} />
          <div className="IndividualProjectHeaderText">
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">01</span>Dashboard for a Blog in
              ReactJs:{" "}
            </h1>
            <p>
              Authentication using JWT, dark mode, and light mode themes.
              Dynamic content editor tailored to administrators' needs, with
              varying inputs, layouts, and types of media. Each blog section
              corresponds to a dedicated dashboard section.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="ColoredContainer">
        <span className="spanstylea">Technologies And tools</span>
        <span className="spanstyleb">
          <span className="numbered">01</span>Framework:
        </span>
        <span className="spanstylec">
          React with the MUI component library.
        </span>
        <span className="spanstyleb">
          <span className="numbered">02</span>3rd party libraries:
        </span>
        <span className="spanstylec">
          React Pro Sidebar- Formik - Yup - Redux - AnimeJs - Firebase - React
          Router Dom
        </span>
        <span className="spanstyleb">
          <span className="numbered">03</span>Custom Components
        </span>
        <span className="spanstylec">3d animated sphere from CodePen</span>
      </div>
      <div className="CaseStudy">
        <div className="cAROUSELtEXT">
          <span className="spanstylea">Case Study</span> <br></br>
          <span className="spanstyleb">
            <span className="numbered">01</span>Problem:
          </span>{" "}
          <br></br>{" "}
          <span className="spanstyled">
            In addition to the active involvement of different writers and
            administrators, the blog for which this dashboard has been created
            encompasses over 30 categories of various post types, diverse media
            files, and distinct sections that require manual updates, and given
            the substantial volume of posts expected to be added, updated, or
            deleted, there is a critical need for a fast and easy management
            system, driven by both efficiency and security considerations.
          </span>
        </div>
        <div className="ContentProjectCOntainer">
          <Carousel
            additionalTransfrom={0}
            centerMode={false}
            draggable
            focusOnSelect={false}
            containerClass="CarouselClassProjects"
            itemClass=""
            infinite={true}
            responsive={responsive}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
          >
            <figure>
              <img src={dashboardAddPost} alt="Dashboard Add Post" />
              <figcaption>
                <span className="spanstyleb"><span className="numbered">03</span>Post Creation page</span><br></br>
                <span className="spanstylec margin-right">Problem soved by this page:</span><br></br>
                <span className="spanstyled">
                  Managing various aspects such as post creation, diverse
                  layouts, backlinks, and dynamic routes adds to the complexity,
                  This is the reason for creating a customised post editor
                </span>
              </figcaption>
            </figure>
            <figure>
              <img src={dashboardEditCategory} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">04</span>Category and homepage manager</span>
              <br></br>
                <span className="spanstylec margin-right">Problem soved by this page</span>
                <br></br>   <span className="spanstyled">
                  The blog necessitates frequent manual updates due to its
                  diverse content and sections. This is the reason why this page is created.
                </span>
              </figcaption>
            </figure>
            <figure>
              <img src={dashboardList} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">05</span>Posts List Page</span>
              <br></br>
                <span className="spanstylec margin-right">Problem soved by this page</span>
                <br></br>   <span className="spanstyled">                  Given the high volume of posts requiring addition, updating,
                  or deletion, there is a pressing need for a swift and
                  efficient management system.
                </span>
              </figcaption>
            </figure>
            <figure>
              <img src={dashboardLogin} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">06</span>Login/Logout Page</span>
              <br></br>
                <span className="spanstylec margin-right">Problem soved by this page</span>
                <br></br>   <span className="spanstyled">                         The blog's security is a paramount concern, particularly with
                  the constant flow of content updates and different types of
                  active users(adminstrators, wroters , visitors ...).
                </span>
              </figcaption>
            </figure>
          </Carousel>
        </div>
      </div>

      {/**********************************************************Project 2 */}
      <div className="ProjectsInnerContainer">
        <div className="IndividualProjectHeader">
          <div className="IndividualProjectHeaderText">
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">02</span>Building a Real-Life Blog with Nuxt.js and SSR
            </h1>
            <p>
            Dynamic, responsive, server-side rendered, search engine-optimized, accessible, with high-quality media files and the ability to download them. It supports various types of media, including JPEG, PNG, MP4, MP3, PDF, and ensures security.
            </p>
          </div>
          <img src={blogmockup} />
        </div>
      </div>
      <div className="ColoredContainer">
        <span className="spanstylea">Technologies And tools</span>
        <span className="spanstyleb">
          <span className="numbered">01</span>Tech stack:
        </span>
        <span className="spanstylec">
        Frontend:<br></br> - NuxtJs with the Bootstrap component library.
        </span>

        <span className="spanstylec">
          Backend:<br></br>- NodeJs with express<br></br>- MySQL for relational as relational database(it contains mainly categories, administrators, and posts Id).<br></br>- MongoDB for content storing(it also contains the content of elements which needs to be modified manually such as the page header).<br></br>- Firebase storage(all media files are stored in Firebase). 
        </span>

        <span className="spanstylec">
        Api testing:<br></br>- For straightforward tests, I utilize Thunder Client, but if I encounter more complex issues, I turn to Postman.
        </span>
        <span className="spanstylec">
        Cloud hosting:<br></br>- Amazone web services(Ec2 Amazone Linux)
        </span>
        <span className="spanstylec">
        Version Control:<br></br>- Git
        </span>
        <img src={Github} style={{height:"40vh",borderRadius:"10px"}}/>

        <span className="spanstylec">
        Domain name provider:<br></br>- Godaddy
        </span>
        
        <span className="spanstylec">
        SSL certificate:<br></br>- ZeroSSL
        </span>
        
        <span className="spanstylec">
        Seo tools:<br></br>- Google search console.<br></br>- Screaming Frog
        </span>


        <span className="spanstyleb">
          <span className="numbered">02</span>3rd party libraries:
        </span>
        <span className="spanstylec">
          NuxtJs sitemap- axios - vue - vue server renderer - vue slick carousel 
         
        </span>
        </div>
      <div className="CaseStudy2">
        <div className="cAROUSELtEXT">
        <span className="spanstylea">Case Study</span> <br></br>
          <span className="spanstyleb">
            <span className="numbered">01</span>Problem:
          </span>
          <br></br>{" "}
          <span className="spanstyled">      
To generate profits through affiliate marketing on platforms such as AdSense, Media.net, Amazone Associates, or by employing dropshipping via platforms like Shopify, the key lies in maximizing the visibility of ads to potential clients. The more prominent the ad, the greater the potential for attracting clients. The strategy is straightforward: begin by creating valuable, useful, helpful, unique, and original content capable of captivating an audience.   </span>
      
<br></br>{" "}<span className="spanstyled">      
The initial step involves establishing a robust social media presence to draw users through compelling content and effective advertising tools. These social media accounts serve a dual purpose. Firstly, they redirect users to the content we've crafted. Secondly, the social media apps themselves monetize through affiliate marketing or dropshipping. </span>
<br></br>{" "}<span className="spanstyled">      
Now, the apps guide users to the social media pages, and from there, users are directed to our content. However, this isn't sufficient. To further enhance reach, it's crucial to generate organic traffic through search engines. Yet, for optimal profit, the platform should also be adaptable for an upgrade to a more sophisticated app. This upgrade should incorporate features like e-commerce, adding another layer of capability to the platform.  </span>

      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
        </div>
        <div className="ContentProjectCOntainer">
          <Carousel
            additionalTransfrom={0}
            centerMode={false}
            draggable
            focusOnSelect={false}
            containerClass="CarouselClassProjects"
            itemClass=""
            infinite={true}
            responsive={responsive}
            arrows={false}
            customButtonGroup={<ButtonGroup2 />}
            renderButtonGroupOutside={true}
          >
            <figure>
              <img src={bloghomepage} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">03</span>Home Page</span>
                <br></br>   <span className="spanstyled">      
                
Each page of the blog is primarily visually structured into four sections. The first is the navbar section, consisting of a top bar designed to prominently display the blog's logo. Additionally, the top bar strategically positions two ad slots to maximize visibility. The right sidebar serves to enhance user experience by ensuring all categories remain visible even while scrolling, and it also features ad placements. The footer aggregates all app URLs, providing about information, contact details, privacy policy, and terms of use.
                </span>
                <br></br>   <span className="spanstyled">      
                
                Finally, the body sections vary depending on the route. For example, on the homepage, the layout comprises the header and "Movies and Shows" sections. These sections aim to showcase the most recent and trending topics and articles, creating an engaging and informative experience for the users.                                </span>
              </figcaption>
            </figure>
            <figure>
              <img src={bloghomepage2} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">03</span>Home Page</span>
                <br></br>   <span className="spanstyled">      
                
These are two additional sections on the homepage. In fact, the entire body of the homepage comprises approximately nine sections specifically designed for showcasing content.
                </span>  </figcaption>
            </figure>
            <figure>
              <img src={blogbuzz} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">04</span>Latest</span>
                <br></br>   <span className="spanstyled">      
                
                This is the most recent page, serving the same purpose as what is referred to: showcasing latest content
                </span>  </figcaption>
            </figure>
          
            <figure>
              <img src={blogpost} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">05</span>Post page</span>
                <br></br>   <span className="spanstyled">      
                
                This is the post page, housing the entirety of the post's content. It begins with a full-width image designed to leave a lasting impression on the reader. The posted image is carefully chosen to be unique, interesting, and in high definition, strategically playing into the psychology of the reader. The remainder of the post is dynamic, featuring varying numbers of paragraphs, internal and external links, and diverse quote layouts. Quotes may appear within paragraphs or as separate sections. Additionally, images can be dynamically positioned at the center, left side, or right side, ensuring a fluid and engaging reading experience.                </span>  </figcaption>
            </figure>
            <figure>
              <img src={blogContact} alt="Dashboard Add Post" />
              <figcaption>
              <span className="spanstyleb"><span className="numbered">06</span>Contact page</span>
                <br></br>   <span className="spanstyled">      
                
                And this is the contact page, designed to be straightforward and easy to navigate, making it clear where everything is located.            </span>  </figcaption>
         
            </figure>
          </Carousel>
        </div>
      </div>
      <div className="ColoredContainer">
        <span className="spanstylea">Application Architecture</span>
        <span className="spanstyleb">
          <span className="numbered">01</span>System diagram:
        </span>
        <figure>
              <img src={Diagram} alt="AnguideHub Architecture" style={{width:"100%", height:"auto"}} />
              <figcaption>
              <span style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", fontFamily:"'Gill Sans', 'Gill Sans MT'", marginTop:"10px"}}>Created by draw.io</span>
            
              </figcaption>
            </figure>
        </div>
      {/******************************************************Cerebros */}
      <div className="ProjectsInnerContainer">
        <div className="IndividualProjectHeader">
          <img src={cerebrosElec} />
          <div className="IndividualProjectHeaderText">
            <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">03</span>Landing Page for a Residential Electrician
            </h1>
            <p>
            This is my first-ever project, whether using a JavaScript framework or just HTML/CSS. I tackled the challenge of customizing a React.js template, even though I had no prior knowledge of how web applications or React worked. I made some mistakes along the way, but it was my first step into web development. Eventually, I deployed the project on Firebase, and GoDaddy handled the domain name.
            </p>
          </div>
        </div>
      </div>
      <div className="ColoredContainer">
        <span className="spanstylea">Technologies And tools</span>
        <span className="spanstyleb">
          <span className="numbered">01</span>Framework:
        </span>
        <span className="spanstylec">
          React with the bootstrap component library and sass.
        </span>
      
      </div>
      <div className="CaseStudy">
        <div className="cAROUSELtEXT">
          <span className="spanstylea">  <span className="numbered">01</span>Started as a brochure ended up as branding:</span> <br></br>
       
          <br></br>{" "}
          <span className="spanstyled">
          The project was for a friend who had recently started working independently but lacked experience. He asked me to create a small presentation in a brochure showcasing his previous work, aiming to make a good first impression on potential clients. To assist him further, I suggested a business name (Cerebros Elec, combining "brain" and "electricity"). I designed various business cards, created a simple logo and deployed the web application.
          </span>
          <br></br><span className="spanstyled">

          Afterwards, I attempted to explore opportunities on Upwork. However, since I lacked experience, projects, and certificates at that time, it wasn't successful. This video was created to display the app on Upwork.           </span>


        </div>
        
        <div className="ContentProjectCOntainer">
        <iframe width="80%" height="315" src="https://www.youtube.com/embed/db_Tj2ugERU?si=sryVcEfRmb-xkXMz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </div>
      </div>
      <div className="ColoredContainer">
      <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">04</span>More Projects
            </h1>      
        <span className="spanstylec">
        In addition to those projects, I successfully developed a blog using Django and Bootstrap by following a YouTube course. Simultaneously, I worked on five HTML/CSS projects while earning the <span className="Highlight">300</span> hours freeCodeCamp Responsive Web certificate. Each of these five projects was broken down into smaller sub-projects, and each sub-project comprised several smaller tasks. In total, I completed <span className="Highlight">739 tasks</span> across these projects.        </span>
      
      </div>
      <div className="ColoredContainer">
      <h1 className="IndividualProjectHeaderTitle">
              <span className="numbered">04</span>Projects on hold
            </h1>      
        <span className="spanstylec">
        Exploring a React Native project for a small business idea. I've drafted some Figma designs and begun the app development. </span>
        <span className="spanstylec">
        Here are the initial Figma designs that I've created, and I plan to enhance and expand them further.</span>
      <img style={{width:"80%",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}} src={initialDesign}></img>
      <img style={{width:"80%",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px"}} src={LatestDesign}></img>
      </div>
      <div style={{ width: "100%", padding: "20px", background: "#000" }}>
            <span className="expression">
              <number className="number">02-</number>&nbsp;&nbsp;&nbsp;&nbsp;
              "Success is stumbling from failure to failure with no loss of enthusiasm." - Winston S. Churchill            </span>
          </div>
      <div className="projectsFooter">
        <Footer />
      </div>
    </div>
  );
};
export default Projects;

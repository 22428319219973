import React from "react";
import "../public/styles.css";
const Content_Right=({children, logo,ClassName,sourceImage})=>{
return(
<div ClassName="ContentContainer">
<div className="ContainerDirectionRight">
    <div style={{width:"25%"}}></div>
    <div className="ContainerRightDirectionRight">
    {children[0]}
    <img src={logo} className={ClassName}/>
    </div>
    <div  className="ContainerRight">
    {children[1]}
    </div>
  </div>
  <div className="BottomImage">
<div style={{width:"25%"}}></div>
<img src={sourceImage} />
</div></div>

)
}
export default Content_Right;

const handleUserInput = (userinput, screen, changeScreen,navigate) => {

  if (screen === "welcome") {
    changeScreen("instructions");
  } else if (screen === "instructions") {
    switch (userinput) {
      case "a":
      case "A":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./home");
        }, 2000);
        break;     
      case "b":
      case "B":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./resume");
        }, 2000);
        break;
      case "c":
      case "C":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./projects");
        }, 2000);
        break;
      case "d":
      case "D":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./certifications");
        }, 2000);
        break;
      case "e":
      case "E":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./technologies");
        }, 2000);
        break;
    
      case "f":
      case "F":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./education");
        }, 2000);
        break;
      case "g":
      case "G":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./professional-experience");
        }, 2000);
        break;
      case "h":
      case "H":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./volunteering");
        }, 2000);
        break;
      case "i":
      case "I":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./other-jobs");
        }, 2000);
        break;
      case "j":
      case "J":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./internship-objectives");
        }, 2000);
        break;

      case "k":
      case "K":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./contact");
        }, 2000);
            break;
      case "l":
      case "L":
        changeScreen("welcome");
        break;
      default:
        changeScreen("wronginput");
    }
  } else if (screen === "wronginput") {
    switch (userinput) {
      case "a":
      case "A":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./home");
        }, 2000);
        break;     
      case "b":
      case "B":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./resume");
        }, 2000);
        break;
      case "c":
      case "C":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./projects");
        }, 2000);
        break;
      case "d":
      case "D":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./certifications");
        }, 2000);
        break;
      case "e":
      case "E":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./technologies");
        }, 2000);
        break;
    
      case "f":
      case "F":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./education");
        }, 2000);
        break;
      case "g":
      case "G":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./professional-experience");
        }, 2000);
        break;
      case "h":
      case "H":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./volunteering");
        }, 2000);
        break;
      case "i":
      case "I":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./other-jobs");
        }, 2000);
        break;
      case "j":
      case "J":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./internship-objectives");
        }, 2000);
        break;

      case "k":
      case "K":
        changeScreen("loading");
        setTimeout(() => {
          navigate("./contact");
        }, 2000);
            break;
      case "l":
      case "L":
        changeScreen("welcome");
        break;
      default:
        changeScreen("wronginput");
    }
  }
};

export default handleUserInput;
